import actions from './actions'

const initialState = {
  StatorderList: null,
  categoryCount: null,
  thisMonthCount: null,
  productCount: null,
  orderCount: null,
  OrderInfoData: null,
  OrderCourierData: null,
  OrderPaymentData: null,
  searchCustomers: null,
  searchOrders: null,
  productInfoData: null,
  selectedData: null,
  imageInfodata: null,
  customerInfoData: null,
  displayOrderDetailDrawer: false,
}

export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
      case actions.HIDE_ORDER_DETAILS:
        return { ...state, selectedData: null, displayOrderDetailDrawer: false }

    default:
      return state
  }
}
