import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import {
  loadAll,
  loadOrderDetails,
  loadStatistics,
  loadAllCustomer,
  changestatus,
} from 'api/orders'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { orderList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        OrderInfoData: orderList,
      },
    })
  }
}

export function* LOAD_ALL_CUSTOMERS() {
  const { response } = yield call(loadAllCustomer)
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfoData: customerList,
      },
    })
  }
}

export function* LOAD_STATISTICS() {
  const { response } = yield call(loadStatistics)
  if (response) {
    const { categoryLength, productLength, OrderTotal, thisMonth, orderList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        categoryCount: categoryLength,
        productCount: productLength,
        orderCount: OrderTotal,
        thisMonthCount: thisMonth,
        StatorderList: orderList,
      },
    })
  }
}

export function* SHOW_ORDER_DETAILS({ payload }) {
  const { response } = yield call(loadOrderDetails, payload)
  if (response) {
    const { orderDetail, courierDetail, paymentDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        OrderDetailData: orderDetail,
        OrderCourierData: courierDetail,
        OrderPaymentData: paymentDetail,
        displayOrderDetailDrawer: true,
      },
    })
  }
}

export function* CHANGE_ORDER_STATUS({ payload }) {
  const { response } = yield call(changestatus, payload)
  if (response) {
    const { orderList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        OrderInfoData: orderList,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.SHOW_ORDER_DETAILS, SHOW_ORDER_DETAILS),
    takeEvery(actions.LOAD_ALL_CUSTOMERS, LOAD_ALL_CUSTOMERS),
    takeEvery(actions.LOAD_STATISTICS, LOAD_STATISTICS),
    takeEvery(actions.CHANGE_ORDER_STATUS, CHANGE_ORDER_STATUS),
  ])
}
