import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import products from './products/sagas'
import orders from './orders/sagas'
import config from './config/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), staff(), products(),orders(), config()])
}
