import { createSelector } from 'reselect'

export const selectUser = state => state.user

export const selectSuperUserData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'superadmin'
})

export const selectUserData = createSelector([selectUser], user => {
  const userInfo = user?.userRole
  if (!userInfo) return false
  return userInfo === 'user'
})
