import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'title.helmet.Leads': 'Manage Leads',
  'title.helmet.services': 'Manage Services',
  'text.servicesList': 'Services List',
  'title.helmet.dailyReports': 'Manage Daily Reports',
  'title.helmet.Tasks': 'Manage Tasks',
  'form.user.placeholder.searchEnquiry': 'Search Enquiry',
  'title.helmet.Enquiry': 'Manage Enquiry',
  'text.enquiryList': 'Enquiry List',
  'text.DailyReportList': 'Daily Report List',
  'text.custommessage.enquiryData': 'No Enquiry Data Found',
  'text.viewEnquiry.sno': 'S.No',
  'text.viewEnquiry.name': 'Name',
  'text.viewEnquiry.email': 'Email',
  'text.viewEnquiry.mobile': 'Mobile',
  'text.viewEnquiry.subject': 'Subject',
  'text.viewEnquiry.website': 'Website',
  'text.viewEnquiry.enquiry_date': 'Enquiry Date',
  'title.helmet.Accounts': 'Manage Accounts',
  'text.leadList': 'Lead List',
  'title.helmet.User': 'Manage Users',
  'text.userList': 'User List',
  'text.updateLeads': 'Update Leads',
  'text.removeLead': 'Remove Lead',
  'text.removeLead.description': 'Are you sure you want to delete this lead?.',
  'text.addLead': 'Add Lead',
  'action.createLeads': 'Create Leads',
  'text.viewleads.sno': 'Sno.',
  'text.viewleads.business_name': 'Business Name',
  'text.viewleads.emailid': 'Email Id',
  'text.viewleads.mobile': 'Mobile',
  'text.viewleads.type': 'Type',
  'text.viewleads.lead_owner': 'Lead Owner',
  'text.viewleads.status': 'Status',
  'text.viewleads.contact_name': 'Contact Name',
  'text.custommessage.leadsData': 'No Leads to show',
  'text.custommessage.accountsData': 'No Accounts to show',
  'form.user.placeholder.searchLeads': 'Search by Business Name or Contact Name or Email Or Mobile',
  'form.user.placeholder.searchAccounts': 'Search by Business Name or Contact Name or Email',
  'text.accountList': 'Accounts List',
  'text.store.tab.activeAccounts': 'Active Accounts List',
  'text.store.tab.inactiveAccounts': 'Inactive Accounts List',
  'text.addReceipt': 'Add Receipt',
  'text.receiptList': 'Receipt List',
  'title.helmet.receipt': 'Receipt',
  'action.addReceipt': 'Add Receipt',
  'form.user.placeholder.searchReceipt': 'Search Receipt',
  'form.placeholder.ProformaInvoice': 'Proforma Invoice',
  'form.label.ProformaInvoice': 'Proforma Number',
  'form.label.proforma_amount': 'Proforma Amount',
  'form.placeholder.payment_type': 'Payment Type',
  'form.label.payment_type': 'Payment Type',
  'form.label.payment_date': 'Payment Date',
  'form.label.balance_amount': 'OutStanding Amount',
  'form.label.advance_payment': 'Advance Payment',
  'form.label.previous_amount': 'Previous Payment',
  'form.label.cheque_date': 'Cheque Date',
  'form.label.cheque_no': 'Cheque Number',
  'form.placeholder.payment_mode': 'Payment Mode',
  'form.label.payment_mode': 'Payment Mode',
  'form.label.transaction_id': 'Transaction UTR Number',
  'form.placeholder.User': 'Select User from list',
  'text.NEFT': 'NEFT',
  'text.IMPS': 'IMPS',
  'text.orderType': 'Order Type',
  'text.GPAY': 'GOOGLE PAY',
  'text.orderTotal': 'Order Total',
  'text.productTotal': 'Product Total',
  'text.shippingTotal': 'Shiping Total',
  'text.taxTotal': 'Tax Total (Product Total * 18%)',
  'text.label': 'Download Label',
  'text.shipment_id': 'Shipment ID',
  'text.payment_type': 'Payment Type',
  'text.courierStatus': 'Courier Status',
  'text.courierType': 'Courier Type',
  'text.awbNumber': 'Courier AWB Number',
  'text.paymentProvider': 'Payment Provider',
  'text.store.tab.courier': 'Courier Info',
  'text.store.tab.payment': 'Payment Info',
  'text.payment_id': 'Payment Id',
  'text.currency': 'Currency',
  'text.merchant_id': 'Merchant Id',
  'text.payment_status': 'Payment Status',

  'text.viewservicesData.payment_date': 'Payment Date',
  'text.viewservicesData.payment_mode': 'Payment Mode',
  'text.viewservicesData.advance_payment': 'Advance Payment',
  'form.placeholder.proformaStatus': 'Payment Status',
  'form.label.proformaStatus': 'Payment Status',
  'text.leadFollowup.description': 'Lead Followup',
  'action.Change': 'Change',
  'action.convertToInvoice': 'Convert To Invoice',
  'form.label.isAccountable': 'Is Accountable',
  'action.nonrecurring': 'No',
  'action.Recurring': 'Yes',
  'text.store.tab.recurring': 'Recurring',
  'text.store.tab.ledger': 'Ledger',
  'text.store.tab.receipts': 'Receipts',
  'text.store.tab.inactiveLeads': 'In Active',
  'text.store.tab.activeLeads': 'Active',
  'text.store.tab.ClosedProforma': 'Closed',
  'text.store.tab.confirmedProforma': 'Confirmed',
  'text.store.tab.Cancelled': 'Cancelled',
  'text.store.tab.Others': 'Other',
  'text.viewDailyReport.status': 'Status',
  'text.viewDailyReport.expires': 'Expires On',
  'text.viewDailyReport.DomainId': 'Domain ID',
  'text.viewDailyReport.domainName': 'Domain Name',
  'text.store.tab.salesProspect': 'Sales',
  'text.store.tab.wipProspects': 'WIP',
  'text.EditSales': 'Edit Sales',
  'text.AddSales': 'Add Sales',
  'action.Balance': 'Get Balance',
  'form.label.salesTotal': 'Sales Total',
  'action.addRenewals': 'Add Renewals',
  'text.AddRenewals': 'Add Renewals',
  'text.store.tab.renewals': 'Renewals',
  'form.label.service_name': 'Service Name',
  'form.placeholder.service_name': 'Service Name',
  'form.user.placeholder.searchrenewals': 'Search Renewals',
  'form.label.balanceInfo': 'Balance Amount',
  'form.label.advanceReceived': 'Advance Received Amount',

  'action.addSales': 'Add Sales',
  'form.user.placeholder.searhprospects': 'Search by Business Name, Deal Name',
  'text.viewProspects.created_date': 'Created Date',
  'text.viewProspects.created_by': 'Created By',
  'text.viewProspects.status': 'Status',
  'text.viewProspects.total_amount': 'Total Amount',
  'text.viewProspects.amount': 'Amount',
  'text.viewProspects.deal': 'Deal Name',
  'text.viewProspects.business_name': 'Business Name',
  'text.viewProspects.gst': 'GST(18%)',
  'text.viewProspects': 'View Prospects',
  'form.label.total_amount': 'Total Amount',
  'form.label.amount': 'Amount',
  'form.label.deal': 'Deal Name',
  'form.placeholder.deal': 'Deal Name',
  'form.label.monthfor': 'Month For',
  'title.helmet.Prospect': 'Prospect',
  'text.viewProspects.monthfor': 'Month For',
  'action.SyncDomain': 'Sync Domain',
  'form.user.placeholder.searchDomainList': 'Search by Domain Name',
  'text.DomainList': 'Domain List',
  'text.custommessage.DomainList': 'No Domain Found',
  'title.helmet.DomainList': 'Domain List',

  'text.custommessage.proformainvoicelist': 'No proforma invoice records found',
  'text.custommessage.invoicelist': 'No invoice records found',
  'text.ProformaInvoice': 'Proforma Invoice',
  'text.store.tab.Invoice': 'Invoice',
  'text.store.tab.proformaInvoice': 'Proforma Invoice',
  'text.businessName': 'Business Name',
  'text.invoiceAmount': 'Total Amount',
  'text.invoiceDate': 'Created Date',
  'text.cost': 'Cost',
  'text.ItemCost': 'Item Cost',
  'text.subtotal': 'Sub Total',
  'text.tax': 'Tax',
  'text.discount': 'Discount',
  'text.totalDue': 'Net Pay',
  'text.createdby': 'Created By',
  'text.invoice': 'Invoice',
  'text.description': 'Description',
  'text.quantity': 'Quantity',
  'text.store.ReportView': 'Daily Report View',
  'text.store.AdddailyReport': 'Add Daily Report',
  'text.paymentProspect': 'Payment Prospect',
  'text.MonthlyTargets': 'Acculer Monthly Targets',
  'text.SalesRevenue': 'Turnover',
  'text.salesProspect': 'Sales Prospect',
  'text.salesProspectChart': 'Sales Prospect Chart',
  'text.SalesRevenueChart': 'Turnover Chart',
  'text.amount': 'Amount',
  'text.paidinvoice': 'Paid',
  'text.PO': 'Proforma Invoice',
  'text.Invoice': 'Invoice',
  'text.billTo': 'Bill To',
  'text.proforma_number': 'Proforma Number',
  'text.invoice_number': 'Invoice Number',
  'action.createInvoice': 'Create Invoice',
  'action.Update': 'Update',
  'action.clear': 'Clear',
  'form.placeholder.search.invoice': 'Search by Business Name or by Invoice Number',
  'text.addItem': 'Add Item',
  'text.createInvoice': 'Create Invoice',
  'form.label.BusinessName': 'Business Name',
  'form.placeholder.BusinessName': 'Select Business Name',
  'form.label.Type': 'Invoice Type',
  'form.placeholder.Type': 'Select Invoice Type',
  'form.label.gst': 'GST %',
  'form.label.cgst': 'CGST %',
  'form.label.sgst': 'SGST %',
  'form.label.igst': 'IGST %',
  'form.placeholder.gst': 'Select GST type from list',
  'form.label.discount': 'Discount in INR',
  'form.placeholder.discount': 'Enter the Discount in INR',
  'text.grandTotal': 'Grand Total',
  'text.BankAccountDetails': 'Bank Account Details',
  'form.label.keynotes': 'Key Notes',
  'form.placeholder.keynotes': 'Enter Key Notes',
  'text.editDomainName': 'Update',
  'text.addEmail': 'Add Email',
  'action.createEmail': 'Create Email',
  'action.AddAdvance': 'Add Advance',
  'text.store.tab.email': 'Email',
  'text.custommessage.renewalsData': 'No data found for Renewals List',
  'text.custommessage.EmailList': 'No data found for Email List',
  'text.custommessage.salesData': 'No data found for Sales Prospects',
  'text.emailType': 'Email with Type',
  'action.changeType': 'Change Type',
  'text.email.search': 'Search Email',
  'title.helmet.accounts': 'Accounts',
  'action.MovetoNextMonth': 'Move to Next Month',
  'text.storeino.tab.accountsInfo': 'Accounts Info',
  'text.storeino.tab.notesInfo': 'History',
  'text.storeino.tab.invoices': 'Invoices',
  'text.storeino.tab.enquiry': 'Enquiry',
  'text.storeino.tab.dailyReports': 'Daily Reports',
  'text.storeino.tab.services': 'Services',
  'text.storeino.tab.renewals': 'Renewals',
  'action.followup': 'Today Lead Followup',
  'text.upcomingLeadFollowup': 'Upcoming Lead Followup',
  'text.TodayleadFollowup': 'Lead Followup',
  'form.placeholder.deliveryStatus': 'Email Delivery Status',
  'text.removeEnquiry.description': 'Do you want to remove the selected Enquiry?.',
  'text.comments': 'Comments',
  'text.custommessage.followupData': 'Comments',
  'title.helmet.Dashboard': 'Dashboard',
  'form.label.followup_date': 'Followup Date',
  'text.followupDate': 'Followup Date',
  'action.updateStatus': 'Update Status',
  'text.QuotationFollowup.description': 'Comments',
  'text.custommessage.leadfollowupData': 'No Data for Followup',
  'form.user.placeholder.leadfollowup': 'Search by Business Name',
  'form.label.current_expiry_date': 'Current Expiry Date',
  'form.placeholder.current_expiry_date': 'Current Expiry Date',
  'form.label.new_expiry_date': 'New Expiry Date',
  'form.placeholder.new_expiry_date': 'New Expiry Date',
  'action.AddFollowup': 'Add Followup',
  'text.servicesFollowup.description': 'Followup Description',
  'text.changeValidity.description': 'Validity',
  'text.TodayServiceFollowup': 'Service Followup',
  'text.removeinvoice.description': 'Do you want to delete this proforma?.',
  'text.todayInvoiceFollowup': 'Today Invoice Followup',
  'text.ServiceFollowup.description': 'Followup Description',
  'text.InvoiceFollowup.description': 'Followup Description',
  'text.todayAccountsFiltered': 'Today Accounts Followup',
  'text.upcomingAccountsFiltered': 'Upcoming Accounts Followup',
  'text.accountsFollowup.description': 'Accounts Followup',
  'text.RenewalsInfo': 'Renewal Info',
  'form.placeholder.filteredDays': 'Filter Days',
  'title.helmet.TimeOff': 'Time Off',
  'form.user.placeholder.searchTimeOff': 'Search Time Off',
  'text.store.timeOff': 'Time Off',
  'text.notApproved': 'Not Approved',
  'text.store.timeOffSettings': 'Time Off Settings',
  'text.sno': 'S.no',
  'text.is_approved': 'is Approved',
  'text.Rejected': 'Rejected',
  'text.Waiting': 'Waiting',
  'text.noofdays': 'No of Days',
  'text.month_year': 'Month/Year',
  'text.TimeOffLogs': 'Time-Off Logs',
  'text.custommessage.timeoffdata': 'No Data for Time-Off',
  'text.requestTimeOff': 'Request Time Off',
  'text.type': 'Time-Off Type',
  'form.label.end_date': 'End Date',
  'form.label.start_date': 'Start Date',
  'form.label.timeOffType': 'Time Off Type',
  'action.requestTimeOff': 'Request Time-Off',
  'form.placeholder.timeOffType': 'Time Off Type',
  'form.placeholder.noofdays': 'No of Days',
  'form.label.noofdays': 'No of Days',
  'text.approved': 'Approved',
  'form.placeholder.available_days': 'Available Days',
  'form.label.available_days': 'Available Days',
  'form.placeholder.source': 'Source',
  'form.label.source': 'Source',
  'form.label.prospect_for': 'Prospect For',
  'form.placeholder.prospect_for': 'Prospect For',
  'text.start_date': 'Start Date',
  'form.placeholder.end_date': 'End Date',
  'form.placeholder.start_date': 'Start Date',
  'text.requested_date': 'Requested Date',
  'text.joing_date': 'Joining Date',
  'text.designation': 'Designation',
  'text.department': 'Department',
  'action.Approve': 'Approve',
  'form.label.status': 'Status',
  'form.placeholder.status': 'Status',
  'form.placeholder.vacation_days': 'Vacation Days',
  'form.placeholder.personal_days': 'Personal Days',
  'form.placeholder.sick_days': 'Sick Days',
  'form.label.vacation_days': 'Vacation Days',
  'form.label.personal_days': 'Personal Days',
  'form.label.sick_days': 'Sick Days',
  'action.Enroll': 'Enroll',
  'form.user.placeholder.searchEmployeeDetails': 'Search Employee Details',
  'text.employeeDetails': 'Employee Details',
  'text.custommessage.employeeDetails': 'No data for Employee Details',
  'text.store.employeeEnrolment': 'Employeee Enrolment',
  'action.OnBoard': 'On Board',
  'form.placeholder.onboardingDate': 'On-Boarding Date',
  'form.label.onboardingDate': 'On-Boarding Date',
  'text.onboardedDate': 'On-Boarded Date',
  'form.label.employees': 'Employees',
  'form.placeholder.employees': 'Employees',
  'form.placeholder.employee_id': 'Employee Id',
  'form.label.employee_id': 'Employee Id',
  'title.helmet.employeeInfo': 'Employee Info',
  'text.storeino.tab.EmployeeProfile': 'Employee Profile',
  'form.placeholder.designation': 'Designation',
  'form.label.designation': 'Designation',
  'form.placeholder.department': 'Department',
  'form.label.department': 'Department',
  'text.storeino.tab.timeOff': 'Time Off',
  'text.addTimeOff': 'Add Time Off',
  'action.addTimeOff': 'Add Time Off',
  'form.placeholder.joing_date': 'Joining Date',
  'form.label.joing_date': 'Joining Date',
  'form.label.dateRange': 'Date Range',
  'form.label.filter': 'Filter By',
  'action.search': 'Search',
  'text.DailyReportChartByClientWise': 'Daily Report by Client Wise',

  'action.createDailyReport': 'Create Daily Report',
  'text.addDailyReport': 'Add Daily Report',
  'form.placeholder.Employee': 'Employee',
  'form.label.Employee': 'Employee',
  'form.label.category': 'Category',
  'form.placeholder.category': 'Category',
  'form.label.subject': 'Subject',
  'form.label.description': 'Description',
  'form.placeholder.subject': 'Subject',
  'form.label.time': 'Time Schedule',
  'form.label.start_time': 'Start Time',
  'form.label.end_time': 'End Time',
  'text.viewDailyReport.sno': 'S.No',
  'text.viewDailyReport.business_name': 'Business Name',
  'text.viewDailyReport.subject': 'Subject',
  'text.viewDailyReport.description': 'Description',
  'text.viewDailyReport.start_time': 'Start Time',
  'text.viewDailyReport.end_time': 'End Time',
  'text.viewDailyReport.created_at': 'Created At',
  'text.viewDailyReport.username': 'User',
  'form.user.placeholder.searchDailyReports': 'Search Daily Reports',
  'text.viewLedger.credit': 'Credit',
  'text.viewLedger.debit': 'Debit',
  'text.viewLedger.business_name': 'Business Name',
  'text.viewLedger.month_year': 'Month/Year',
  'text.viewLedger.sno': 'S.No',
  'text.receiptsList': 'Receipt List',
  'text.storeino.tab.ledger': 'Ledger List',
  'text.ledgerList': 'Ledger',
  'text.storeino.tab.receipts': 'Receipts',
  'text.viewLedger.payment_date': 'Payment Date',
  'form.label.dob': 'Date Of Birth',
  'text.custommessage.LedgerData': 'NO data found for Ledger',
  'form.label.ifsc_code': 'IFSC Code',
  'form.label.account_no': 'Account Number',
  'form.label.bank_name': 'Bank Name',
  'form.label.account_holder_name': 'Account Holder Name',
  'form.label.branch_name': 'Branch Name',
  'text.storeino.tab.bankDetails': 'Bank Information',
  'text.store.UserProfile': 'Profile Information',
  'text.store.bankInformation': 'Bank Information',
  'text.storeino.tab.payrollConfig': 'Payroll Config',
  'form.placeholder.bonus': 'Bonus',
  'form.label.bonus': 'Bonus',

  'form.placeholder.medical_allowance': 'Medical Allowance',
  'form.label.medical_allowance': 'Medical Allowance',

  'form.placeholder.special_allowance': 'Special Allowance',
  'form.label.special_allowance': 'Special Allowance',

  'form.placeholder.hra': 'HRA',
  'form.label.hra': 'HRA',

  'form.placeholder.basic_pay': 'Basic Pay',
  'form.label.basic_pay': 'Basic Pay',

  'form.placeholder.ifsc_code': 'IFSC Code',
  'form.placeholder.branch_name': 'Branch Name',
  'form.placeholder.account_holder_name': 'Account Holder Name',
  'form.placeholder.bank_name': 'Bank Name',
  'form.placeholder.account_no': 'Account Number',

  'form.label.monthly_salary': 'Monthly Salary',
  'form.placeholder.monthly_salary': 'Monthly Salary',
  'text.created_at': 'Created At',
  'text.lop_days': 'LOP Days',
  'text.cl_adjusted_days': 'CL Adjusted',
  'text.leave_days': 'Leave Days',
  'text.total_working_days': 'Total Working Days',
  'text.storeino.tab.payrollGeneration': 'Payroll Generation',
  'text.custommessage.paySlipData': 'No data found for Payslip Information',

  'action.calculate': 'Calculate',
  'text.viewservicesData.sno': 'S.No',
  'text.viewservicesData.business_name': 'Business Name',
  'text.viewservicesData.service_name': 'Services Name',
  'text.viewservicesData.started_at': 'Valid From',
  'text.viewservicesData.Validity': 'Validity',
  'text.viewservicesData.username': 'User',
  'text.viewservicesData.created_at': 'Created At',
  'action.createServices': 'Add Services',
  'text.addServices': 'Add Services',
  'form.user.placeholder.searchServices': 'Search Services',
  'form.label.serviceName': 'Service Name',
  'form.placeholder.serviceName': 'Service Name',
  'form.label.started_at': 'Subscription From',
  'form.label.validity': 'Subscribe for years',
  'form.placeholder.validity': 'Validity',
  'text.viewservicesData.expiry_date': 'Validity Till',
  'form.label.validtytill': 'Validity Till',
  'text.custommessage.domainNameList': 'No data for Domain Name List',
  'text.custommessage.hostingList': 'No data for Hosting List',
  'text.business_name': 'Business Name',
  'text.domainname': 'Domain Name',
  'text.serverIpaddress': 'Ip Address - Server Name',
  'text.validity': 'Validity(Yrs)',
  'text.registered_date': 'Registered Date',
  'form.placeholder.started_at': 'Registered Date',
  'text.expiry_date': 'Expiry Date',
  'form.label.followupDate': 'Followup Date',
  'form.label.comments': 'Comments',
  'form.placeholder.expiry_date': 'Expiry Date',
  'text.domainname.search': 'Search Domain Name',
  'text.hosting.search': 'Search Hosting',
  'text.store.tab.domain': 'Domain',
  'form.label.domainname': 'Domain',
  'action.createDomain': 'Add Domain',
  'text.addDomainName': 'Add Domain',
  'form.label.expiry_date': 'Expiry Date',
  'form.placeholder.domainname': 'Enter Domain Name',
  'form.placeholder.DomainName': 'Enter Domain Name',
  'form.label.DomainName': 'Enter Domain Name',
  'form.label.registeredat': 'Registered Date',
  'text.store.tab.hosting': 'Hosting',
  'action.createHosting': 'Create Hosting',
  'text.addHosting': 'Add Hosting',
  'text.editHosting': 'Edit Hosting',
  'form.label.servertype': 'Server Type',
  'form.placeholder.servertype': 'Server Type',
  'form.placeholder.ipaddress': 'IP Address',
  'form.label.ipaddress': 'IP Address',
  'text.sendEmailDescription': 'Do you wan to send Email?.',
  'action.sendEmail': 'Send Email',

  'text.crm': 'CRM',
  'text.website': 'Website',
  'text.graphicdesign': 'Graphic Design',
  'text.digitalmarketing': 'Digital Marketing',
  'text.seo': 'SEO',
  'text.Tasks': 'Tasks',
  'text.AddTasks': 'Add Tasks',
  'action.createTasks': 'Create Tasks',
  'form.user.placeholder.searchTasks': 'Search Tasks',
  'form.label.due_date': 'Due Date',
  'form.placeholder.task_name': 'Task Name',
  'form.label.task_name': 'Task Name',
  'form.placeholder.task_category_id': 'Category Name',
  'form.label.task_category_id': 'Category Name',
  'form.label.assigned_to': 'Assigned To',
  'text.store.tab.inProgressTask': 'In Progess',
  'text.store.tab.toDoTask': 'To Do',
  'text.store.tab.cancelled': 'Cancelled',
  'text.store.tab.Rejected': 'Rejected',
  'text.store.tab.Completed': 'Completed',
  'text.store.tab.Approved': 'Approved',
  'text.store.tab.underReview': 'Under Review',
  'text.viewDailyReport.Task Status': 'Task Status',
  'text.viewDailyReport.assigned_to': 'Assigned To',
  'text.viewDailyReport.due_date': 'Due date',
  'text.viewDailyReport.task_name': 'Task Name',
  'text.viewDailyReport.category': 'Category Name',
  'form.label.message': 'Message',
  'text.viewSms.created_date': 'Created Date',
  'text.viewSms.message': 'Message',
  'text.viewSms.business_name': 'Business Name',
  'text.viewSms.sno': 'S.No',
  'text.custommessage.Notificationdata': 'No Notification data',
  'action.SendSms': 'Send SMS',
  'form.user.placeholder.searchSms': 'Search SMS',
  'form.label.DatePicker': 'Date Picker',
  'action.filtersearch': 'Filter',
  'text.store.tab.Bug': 'Reported Bug',
  'text.AccountsFiltered': 'Accounts Followup',
  'text.leadFollowup': 'Leads Followup',
  'action.SetDNS': 'Set DNS',

  'text.changeAccountManager.description': 'Account Manager',
  'form.label.accountManager': 'Account Manager',
  'form.placeholder.accountManager': 'Account Manager',
  'text.noaccountsnotestoshow': 'No notes to show',
  'text.AddAccountNotes': 'Add Notes',
  'text.updateAccounts': 'Update Accounts',
  'form.label.accountStatus': 'Account Status',
  'text.changeLeadOwner.description': 'Change Lead Owner',
  'text.changeLeadOwner': 'Change Lead Owner',
  'form.label.lead_owner': 'Lead Owner',
  'form.placeholder.changeLeadOwner': 'Select the Lead Owner',
  'text.AddLeadNotes': 'Add Notes for Lead',
  'text.nonotestoshow': 'No Lead Notes to Show',
  'text.taskNotes': 'No Task Notes to Show',
  'text.updateTask': 'Update Task',
  'action.convertToAccounts': 'Convert to Accounts',
  'text.convertAccounts.description': 'Are you sure you want to Convert this lead to Accounts?.',

  'text.viewAccounts.sno': 'Sno.',
  'text.viewAccounts.business_name': 'Business Name',
  'text.viewAccounts.emailid': 'Email Id',
  'text.viewAccounts.mobile': 'Mobile',
  'text.viewAccounts.contact_name': 'Contact Name',
  'text.viewAccounts.accountManager': 'Account Manager',
  'text.viewAccounts.accountAssignedto': 'Assigned to',

  'form.label.business_name': 'Business Name',
  'form.placeholder.business_name': 'Business Name',
  'form.label.contact_name': 'Contact Name',
  'form.placeholder.contact_name': 'Contact Name',
  'form.label.emailid': 'Email Id',
  'form.placeholder.emailid': 'Email Id',
  'form.label.mobile': 'Mobile Number',
  'form.label.phone': 'Phone Number',
  'form.placeholder.phone': '0422 264925',
  'form.label.website_url': 'Website URL',
  'form.placeholder.website_url': 'https://www.example.com',
  'form.label.type': 'Type',
  'form.placeholder.type': 'Select the Type from list',
  'form.label.gst_number': 'GST Number',
  'form.placeholder.gst_number': '22AABCU9603R1ZX',
  'form.label.company_since': 'Company Since',
  'form.placeholder.company_since': '1947',
  'form.label.leadstatus': 'Lead Status',
  'form.label.addressline1': 'Address Line 1',
  'form.placeholder.addressline1': 'Address Line 1',
  'form.label.addressline2': 'Address Line 2',
  'form.placeholder.addressline2': 'Address Line 2',
  'form.label.addressline3': 'Address Line 3',
  'form.placeholder.addressline3': 'Address Line 3',
  'form.label.city': 'City',
  'form.placeholder.city': 'City',
  'form.label.landmark': 'Land Mark',
  'form.placeholder.landmark': 'Land Mark',
  'form.label.state': 'State',
  'form.placeholder.state': 'State',
  'form.label.country': 'Country',
  'form.placeholder.country': 'Country',
  'form.label.pincode': 'Pincode',
  'form.placeholder.pincode': 'Pincode',

  'action.active': 'Active',
  'action.inactive': 'Inactive',
  'text.action': 'Action',
  'action.createUser': 'Create User',
  'text.changeAssigned.description': 'Assign Accounts to',

  'text.serialNo': 'S.No',
  'text.name': 'Name',
  'text.mobile': 'Mobile',
  'text.email': 'Email',
  'text.role': 'Role',
  'text.status': 'Status',
  'text.addUser': 'Add User',
  'text.updateUser': 'Update User',
  'form.label.user_status': 'Status',
  'form.label.phoneoptional': 'Mobile Number (Optional)',
  'form.user.placeholder.searchstaff': 'Search by Name or Email',
  'form.label.contactName': 'Name',
  'form.placeholder.contactName': 'Enter the name',
  'form.placeholder.mobile': '(123) 456-7890',
  'text.custommessage.staffData': 'No Staff Records Available',
  'text.removeUser.description': 'Are you sure you want to delete this user?.',

  'text.space': '{count, plural, =1{1} other{#}}',
  'text.default': 'Default',
  'text.makedefault': 'Make this card as default',
  'action.addnewcard': 'Add New Card',
  'action.add': 'Add',
  'action.addcard': 'Add Card',
  'action.save': 'Save',
  'action.yes': 'Yes',
  'action.no': 'No',
  'action.addCustomImage': 'Add Custom Image',
  'text.logo': 'Logo',
  'text.showaddcard': 'Add New Card',
  'text.storedesign': 'Store Design',
  'text.viewStore': 'View Store',
  'form.label.mobileappcoverimage': 'Mobile App Cover Image',
  'form.label.themeColor': 'Theme Color',
  'action.changeColor': 'Change Color',
  'desc.coverImage': 'Cover Image',
  'desc.backgroundImage': 'Cover Image',
  'action.change': 'Change',
  'text.storeino.tab.orders': 'Orders',
  'text.deviceinfo.Settings': 'Settings',
  'text.tabepaysettings': 'Table Pay Settings',
  'text.storeino.tab.tablepaysettings': 'Table Pay Settings',
  'text.tablepaysettings': 'Table Pay Settings',
  'form.label.webstorebackgroundimage': 'Web Store background Image',
  'note.logoSize':
    'Image dimension recommended in JPG or PNG format with the size less than 200kb.',
  'action.resend': 'Resend',
  'action.edit': 'Edit',
  'action.remove': 'Remove',
  'action.retry': 'Retry',
  'action.update': 'Update',
  'action.void': 'Void',
  'text.resendinvoice': 'Resend Invoice',
  'text.showresendinvoice': 'Resend Invoice',
  'text.voidinvoice': 'Void Invoice',
  'text.showvoidinvoice': 'Void Invoice',
  'text.resendinvoice.description': 'Do you want to resend this invoice?',
  'text.voidinvoice.description': 'Do you want to void this invoice?',
  'text.retrypayment': 'Retry Payment',
  'text.showretrypayment': 'Retry Payment',
  'text.showupdatepayment': 'Update Payment',
  'text.retrypayment.description': 'Do you want to retry the payment?',
  'text.updatepayment.description': 'Do you want to update the payment?',

  'title.helmet.store': 'Manage Stores',
  'text.manage.stores': 'Manage Stores',
  'text.store.tab.active': 'Active',
  'text.store.tab.inactive': 'Inactive',
  'text.store.tab.demo': 'Demo',

  'text.active.store.sno': 'S.No',
  'text.active.store.store_name': 'Store Name',
  'text.active.store.phone_number': 'Phone Number',
  'text.active.store.email': 'Email',
  'text.active.store.reseller': 'Reseller',
  'text.active.store.agent': 'Agent',
  'text.active.store.billing_type': 'Payment Method',
  'action.active.store.billing_type': 'Change Payment Method',
  'text.active.store.pos_software': 'Restaurant Software',
  'text.active.store.search': 'Search by store name or email or reseller',

  'text.inactive.store.sno': 'S.No',
  'text.inactive.store.store_name': 'Store Name',
  'text.inactive.store.phone_number': 'Phone Number',
  'text.inactive.store.email': 'Email',
  'text.inactive.store.reseller': 'Reseller',
  'text.inactive.store.agent': 'Agent',
  'text.inactive.store.billing_type': 'Payment Method',
  'action.inactive.store.billing_type': 'Change',
  'text.inactive.store.search': 'Search by store name or email or reseller',

  'text.demo.store.sno': 'S.No',
  'text.demo.store.store_name': 'Store Name',
  'text.demo.store.phone_number': 'Phone Number',
  'text.demo.store.email': 'Email',
  'text.demo.store.reseller': 'Reseller',
  'text.demo.store.agent': 'Agent',
  'text.demo.store.billing_type': 'Payment Method',
  'action.demo.store.billing_type': 'Change',
  'text.demo.store.search': 'Search by store name or email or reseller',

  'title.helmet.storeinfo': 'Store Info',
  'text.storeinfo': 'Store Info',
  'text.storeino.tab.storeinfo': 'Store Info',
  'text.storeino.tab.subscriptioninfo': 'Subscription',
  'text.storeino.tab.deviceinfo': 'Device Info',
  'text.storeino.tab.paymenthistory': 'Payment History',
  'text.storeino.tab.notes': 'Notes',
  'text.storeino.tab.openorder': 'Open Order',
  'text.storeino.tab.closeorder': 'Closed Order',
  'text.storeino.tab.coseorder': 'Closed Order',

  'text.storeino.tab.windowsclient': 'Windows Client',
  'text.storeino.tab.paymentterminal': 'Payment Terminal',

  'title.helmet.deviceinfo': 'Device Info',
  'text.deviceinfo.sno': 'S.No',
  'text.deviceinfo.devicename': 'Device Name',
  'text.deviceinfo.ipaddress': 'IP Address',
  'text.deviceinfo.macaddress': 'MAC Address',
  'text.deviceinfo.lastactivity': 'Last Activity',
  'text.deviceinfo.networkstatus': 'Network Status',
  'text.deviceinfo.logs': 'Logs',
  'text.deviceinfo.devicetype': 'Device Type',
  'text.deviceinfo.viewlogs': 'View Logs',
  'text.devicelogs': 'Device Logs',
  'text.deviceinfo.servicestatus': 'Service Status',
  'text.deviceinfo.servicestatusrunning': 'Running',
  'text.deviceinfo.servicestatusstopped': 'Stopped',
  'text.deviceinfo.version': 'Version',
  'form.paymentterminal.placeholder.search': 'Search by device name',
  'form.windowsclient.placeholder.search': 'Search by device name',
  'text.errorlogsmessage': 'No Error Logs to show',

  // Open Order Table Header

  'text.openorder.sno': 'S.No',
  'text.openorder.orderno': 'Order Number',
  'text.openorder.ordertype': 'Order Type',
  'text.openorder.tableno': 'Table Number',
  'text.openorder.seatno': 'Seat Number',
  'text.openorder.servername': 'Server Name',
  'text.openorder.ordertotal': 'Order Total',
  'text.openorder.paymentstatus': 'Payment Status',
  'text.openorder.order_status': 'Order Status',
  'text.openorder.orderinfo': 'Order Info',
  'text.openorder.orderdetail': 'Order Detail',
  'text.openorder.search': 'Search by order number or user',
  'text.orderamountdue': 'Amount Due',
  'text.orderno': 'Order#',
  'text.orderamountpaidwithtip': 'Amount Paid (Incl.tip)',
  'text.orderamountpaid': 'Amount Paid',
  'text.openorder.user': 'User',

  'text.ordertableno': 'Table Number',
  'text.orderseatno': 'Seat Number',
  'text.orderpaymentstatus': 'Payment Status',
  'text.ordertype': 'Order Type',
  'text.ordertip': 'Tip',
  'text.orderusername': 'User',
  'text.ordercreatedon': 'Created',
  'text.orderinfo': 'Order Summary',
  'text.orderpaymentmethod': 'Payment Method',
  'text.ordertransactiontype': 'Transaction Type',
  'text.orderauthnumber': 'Auth Number',
  'text.ordertransactiondate': 'Transaction Date/Time',

  'text.closeorder.sno': 'S.No',
  'text.closeorder.orderno': 'Order Number',
  'text.closeorder.ordertype': 'Order Type',
  'text.closeorder.tableno': 'Table Number',
  'text.closeorder.seatno': 'Seat Number',
  'text.closeorder.servername': 'Server Name',
  'text.closeorder.ordertotal': 'Order Total',
  'text.closeorder.orderdetail': 'Order Detail',
  'text.closeorder.paymentstatus': 'Payment Status',
  'text.closeorder.search': 'Search by order number or user',
  'text.closeorder.user': 'User',

  'form.storeinfo.label.storename': 'Store Name',

  'text.storedetail.storedetailinfo': 'Store Details',
  'text.storedetail.contactdetails': 'Contact Details',
  'text.storedetail.businessdetails': 'Business Details',
  'action.storedetail.update': 'Update',

  'form.storedetail.label.storename': 'Store Name',
  'form.storedetail.label.storeaddress': 'Address',
  'form.storedetail.label.storecity': 'City',
  'form.storedetail.label.storeprovince': 'Province',
  'form.storedetail.label.storepostalcode': 'Zip/Postal Code',
  'form.storedetail.label.storecountry': 'Country',
  'form.storedetail.label.storebusinessid': 'Business ID',
  'form.storedetail.label.storephone': 'Phone Number',
  'form.storedetail.label.storealternatephone': 'Alt. Phone Number',
  'form.storedetail.label.storeemail': 'Email',
  'form.storedetail.label.storeowner': 'Business Owner',
  'form.storedetail.label.storetype': 'Restaurant Type',
  'form.storedetail.label.storelocations': 'No. of Locations',
  'form.storedetail.label.storeintersection': 'Intersection/Plaza',
  'form.storedetail.label.storecategory': 'Category',
  'form.storedetail.label.storepricerange': 'Price Range',
  'form.storedetail.label.firstName': 'First Name',
  'form.storedetail.label.lastName': 'Last Name',
  'form.storedetail.label.email': 'Email',
  'form.storedetail.label.phone_number': 'Phone Number',
  'form.storedetail.label.alternate_phone_number': 'Alt. Phone Number',
  'form.storedetail.label.businessid': 'Business ID',
  'form.storedetail.label.locationid': 'Location ID',
  'form.storedetail.label.nooflocations': 'No of Locations',
  'form.storedetail.label.category': 'Category',
  'form.storedetail.label.intersectionplaza': 'Intersection Plaza',
  'form.storedetail.label.pricerange': 'Price Range',
  'form.label.businesstype': 'Business Type',
  'form.label.username': 'Username',

  'form.storedetail.placeholder.storename': 'Enter the store name',
  'form.storedetail.placeholder.storetype': 'Enter the Restaurant Type',
  'form.storedetail.placeholder.storeaddress': 'Enter the address',
  'form.storedetail.placeholder.storecity': 'Enter the City',
  'form.storedetail.placeholder.storeprovince': 'Enter the Province',
  'form.storedetail.placeholder.storepostalcode': 'Enter the Zip/Postal code',
  'form.storedetail.placeholder.storecountry': 'Enter the country',
  'form.storedetail.placeholder.firstName': 'Enter the first name',
  'form.storedetail.placeholder.lastName': 'Enter the last name',
  'form.storedetail.placeholder.email': 'Enter the email',
  'form.storedetail.placeholder.phone_number': '(123) 456-7890',
  'form.storedetail.placeholder.alternate_phone_number': '(123) 123-1234',
  'form.storedetail.placeholder.businessid': 'Enter the business ID',
  'form.storedetail.placeholder.locationid': 'Enter the location ID',
  'form.storedetail.placeholder.nooflocations': 'Locations',
  'form.storedetail.placeholder.category': 'Enter the category',
  'form.storedetail.placeholder.intersectionplaza': 'Enter the Intersection Plaza',

  'title.helmet.user': 'Manage Users',
  'text.user': 'Manage Users',
  'text.userinfo.sno': 'S.No',
  'text.userinfo.name': 'Name',
  'text.userinfo.email': 'Email',
  'text.userinfo.phone_number': 'Phone Number',
  'action.userinfo.edit': 'Edit',
  'text.userinfo.status': 'Status',
  'text.userinfo.action': 'Action',
  'text.userinfo.active': 'Active',
  'text.userinfo.inactive': 'Inactive',
  'text.user.placeholder.search': 'Search..',

  'title.helmet.invoice': 'Invoice',
  'text.viewinvoices': 'View Invoice',
  'text.invoices': 'Manage Invoices',
  'text.paidinvoicelabel': 'PAID',
  'text.notpaidinvoicelabel': 'NOT PAID',
  'text.invoices.placeholder.search': 'Search..',

  'text.invoice.creditcardbilling': 'Credit Card Billing',
  'text.invoice.creditcard.paid': 'Paid Invoices',
  'text.invoice.creditcard.unpaid': 'Unpaid Invoices',
  'text.paymenthistory.paid.sno': 'S.No',
  'text.paymenthistory.paid.invoice_no': 'Invoice Number',
  'text.paymenthistory.paid.storename': 'Store Name',
  'text.paymenthistory.paid.invoice_amount': 'Invoice Amount',
  'text.paymenthistory.paid.invoice_date': 'Invoice Date',
  'text.paymenthistory.paid.payment_status': 'Payment Status',
  'text.paymenthistory.paid.payment_method': 'Payment Method',
  'text.paymenthistory.paid.payment_date': 'Payment Date',
  'text.paymenthistory.paid..placeholder.search': 'Search..',

  'text.creditcard.paid.sno': 'S.No',
  'text.creditcard.paid.invoice_no': 'Invoice Number',
  'text.creditcard.paid.storename': 'Store Name',
  'text.creditcard.paid.invoice_amount': 'Invoice Amount',
  'text.creditcard.paid.invoice_date': 'Invoice Date',
  'text.creditcard.paid.payment_status': 'Payment Status',
  'text.creditcard.paid.payment_method': 'Payment Method',
  'text.creditcard.paid.payment_date': 'Payment Date',
  'text.creditcard.paid..placeholder.search': 'Search by invoice number or invoice date',

  'text.invoice.bamboradebit.paid': 'Paid Invoices',
  'text.invoice.bamboradebit.unpaid': 'Unpaid Invoices',
  'text.bamboradebit.paid.sno': 'S.No',
  'text.bamboradebit.paid.invoice_no': 'Invoice Number',
  'text.bamboradebit.paid.storename': 'Store Name',
  'text.bamboradebit.paid.invoice_amount': 'Invoice Amount',
  'text.bamboradebit.paid.invoice_date': 'Invoice Date',
  'text.bamboradebit.paid.payment_status': 'Payment Status',
  'text.bamboradebit.paid.payment_method': 'Payment Method',
  'text.bamboradebit.paid.payment_date': 'Payment Date',
  'text.bamboradebit.paid..placeholder.search': 'Search..',

  'text.invoice.cloverbilling': 'Clover Billing',
  'text.invoice.cloverecommerce.paid': 'Paid Invoices',
  'text.invoice.cloverecommerce.unpaid': 'Unpaid Invoices',
  'text.cloverecommerce.paid.sno': 'S.No',
  'text.cloverecommerce.paid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.paid.storename': 'Store Name',
  'text.cloverecommerce.paid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.paid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.paid.payment_status': 'Payment Status',
  'text.cloverecommerce.paid.payment_method': 'Payment Method',
  'text.cloverecommerce.paid.payment_date': 'Payment Date',
  'text.cloverecommerce.paid..placeholder.search': 'Search..',

  'text.cloverecommerce.unpaid.sno': 'S.No',
  'text.cloverecommerce.unpaid.invoice_no': 'Invoice Number',
  'text.cloverecommerce.unpaid.storename': 'Store Name',
  'text.cloverecommerce.unpaid.invoice_amount': 'Invoice Amount',
  'text.cloverecommerce.unpaid.invoice_date': 'Invoice Date',
  'text.cloverecommerce.unpaid.payment_status': 'Payment Status',
  'text.cloverecommerce.unpaid.payment_method': 'Payment Method',
  'text.cloverecommerce.unpaid.payment_date': 'Payment Date',
  'text.cloverecommerce.unpaid..placeholder.search': 'Search..',

  'text.invoice.poyntbilling': 'Poynt Billing',
  'text.invoice.bamborabillingbilling': 'Bambora Billing',

  'text.receiptsettings': 'Receipt Settings',
  'text.preferences': 'Preferences',
  'form.label.printreceipt': 'Print receipt after the order is completed?',
  'form.label.printmerchantcopy': 'Print merchant copy after the order is completed?',
  'form.label.footerline': 'Footer Line Text',
  'form.error.footerline.required': 'Please enter the text',
  'form.placeholder.footerline': 'Please enter the footer line text',
  'form.label.idletimeout': 'Idle Timeout (In seconds)',
  'form.error.idletimeout.required': 'Idle timeout required',
  'form.placeholder.idletimeout': '0 means disabled',
  'form.label.tipamount': 'Tip Options',
  'form.label.tip': 'Tip',
  'form.label.tipoptions': 'Choose exactly 3 tip options',

  'form.placeholder.message': 'Type your notes here',

  'text.active': 'Active',
  'text.inactive': 'Inactive',
  'text.demo': 'Demo',
  'text.viewactivitylog': 'Activity Log',
  'text.removeuser.description': 'Are you sure you want to remove the user?',

  'form.placeholder.search': 'Search..',

  'text.stafflist': 'Manage Users',
  'text.totalCount': '{count, plural, =1{1 record} other{# records}}',
  'text.editstaff': 'Update User',

  'text.viewstaff.sno': 'S.No.',
  'text.viewstaff.name': 'Name',
  'text.viewstaff.email': 'Email',
  'text.viewstaff.mobile': 'Phone Number',
  'text.viewstaff.role': 'Role',
  'text.viewstaff.status': 'Status',
  'text.viewstaff.action': 'Action',
  'text.removeUser': 'Remove {name}',
  'text.createstaff': 'Create User',
  'action.createstaff': 'Create User',
  'form.label.role': 'Role',
  'form.label.name': 'Contact Name',
  'form.label.intersection': 'Intersection',

  'form.label.reseller': 'Reseller',
  'form.placeholder.role': 'Select the role',
  'form.placeholder.name': 'Enter the name',
  'form.placeholder.intersection': 'Enter the Intersection',
  'form.placeholder.password': 'Enter the password',
  'form.placeholder.reseller': 'Select the Reseller',
  'action.create': 'Create',
  'form.label.timezone': 'Timezone',
  'form.error.timezone.required': 'Please select the timezone',
  'form.placeholder.timezone': 'Select the timezone',
  'text.payslipMonth': 'Payslip Month',

  'form.label.advance_deductions': 'Advance Deductions',
  'form.placeholder.advance_deductions': 'Advance Deductions',
  'form.placeholder.total_working_days': 'Total Working Days',
  'form.label.total_working_days': 'Total Working Days',
  'form.placeholder.lop_days': 'Loss Of Pay',
  'form.label.lop_days': 'Loss Of Pay(In Days)',
  'form.placeholder.cl_adjusted_days': 'Adjusted Days',
  'form.label.cl_adjusted_days': 'Adjusted Days',
  'form.placeholder.leave_days': 'Leave Days',
  'form.label.leave_days': 'Leave Days',
  'text.generatePayslip': 'Generate Payslip',
  'action.generatePayslip': 'Generate Payslip',
  'action.GeneratePaySlip': 'Generate Payslip',

  'text.changepayment': 'Change Payment Method',
  'form.label.changepayment': 'Payment Method',
  'form.placeholder.changepayment': 'Select payment method',
  'text.billingtype': 'Payment Method',
  'form.label.cardNumber': 'Card Number',
  'form.label.creditCard': 'Credit Card',
  'form.label.cvv': 'CVV',
  'form.label.zipPostalCode': 'Zip/Postal Code',
  'form.placeholder.zipcode': 'Zip/Postal Code',
  'form.placeholder.cardName': 'Name on card',
  'form.label.cardname': 'Name',
  'form.error.required': 'Required',
  'text.billingtype.sno': 'S.No.',
  'text.billingtype.card': 'Payment Method',
  'text.billingtype.action': 'Action',
  'text.Action': 'Action',
  'action.ChangeDueDate': 'Change',
  'form.placeholder.current_due_date': 'Current Due Date',
  'text.duedateChange.description': 'Description',
  'form.label.current_due_date': 'Current Due Date',
  'text.addCategory': 'Add Category',
  'action.addCategory': 'Add Category',
  'form.user.placeholder.searchcategory': 'Search Category',
  'text.categoryImage': 'Category Image',
  'text.CategoryName': 'Category Name',
  'form.placeholder.categoryname': 'Category Name',
  'form.label.categoryname': 'Category Name',
  'text.custommessage.CategoryData': 'No data found for Category List',
  'text.store.tab.catagory': 'Category',
  'action.chooseImage': 'Choose Image',
  'text.UpdateCategory': 'Update Category',
  'text.updateCategory': 'Update Category',
  'form.placeholder.deliveryCost': 'Delivery Cost',
  'text.store.tab.products': 'Products',
  'text.addProductname': 'Add Product',
  'action.addProductName': 'Add Product',
  'form.label.deliveryCost': 'Delivery Cost',
  'form.placeholder.itemPrice': 'Item Price',
  'form.label.itemprice': 'Item Price',
  'form.itemPrice.required': 'Item Price Required',
  'form.placeholder.mrpPrice': 'MRP',
  'form.label.mrpPrice': 'MRP',
  'form.mrpPrice.required': 'MRP Required',
  'form.placeholder.description': 'Description',
  'form.label.tags': 'Tags',
  'form.placeholder.quantity': 'Quantity',
  'form.label.quantity': 'Quantity',
  'form.placeholder.sale_price': 'Sale Price',
  'form.sale_price.required': 'Sale Price Required',
  'form.label.sale_price': 'Sale Price',
  'form.placeholder.price': 'Price',
  'form.price.required': 'Price Required',
  'form.label.price': 'Price',
  'text.price': 'Price',
  'text.AddImages': 'Add Images',
  'action.delete': 'Delete',
  'text.custommessage.ProductImages': 'No data found for Product Images',
  'text.ViewproductImages': 'View Product Images',
  'form.placeholder.productname': 'Product Title',
  'text.productname': 'Product Title',
  'text.title': 'Product Title',
  'form.label.productname': 'Product Title',
  'form.user.placeholder.searchProductNameList': 'Search By Product',
  'text.categoryname': 'Category',
  'text.AddProductImages': 'Add Images',
  'text.custommessage.productNameListData': 'No data found for Product',

  'action.addBanner': 'Add Banner',
  'form.user.placeholder.searchBanner': 'Search Banner',
  'text.viewBanner.title': 'View Banner',
  'text.viewBanner.serialNo': 'S.No',
  'text.bannerImage': 'Banner Image',
  'text.viewBanner.sm_desc': 'Small Description',
  'text.viewBanner.subtitle': 'Subtitle',
  'text.custommessage.bannerData': 'No data found for Banner',
  'text.store.tab.banner': 'Banner',
  'text.addBanner': 'Add Banner',
  'form.placeholder.sm_desc': 'Small Description',
  'form.label.sm_desc': 'Small Description',
  'form.placeholder.title': 'Title',
  'form.label.title': 'Title',
  'form.placeholder.subtitle': 'Sub Title',
  'form.label.subtitle': 'Sub Title',
  'text.updatBanner': 'Update Banner',
  'text.updateBanner': 'Update Banner',
  'text.OrderTotal': 'Order Total',
  'text.customerMobile': 'Customer Mobile',
  'text.customerName': 'Customer Name',
  'text.is_active': 'Status',
  'text.CustomerMobileNo': 'Customer Mobile',
  'text.customerEmail': 'Customer Email',
  'text.customercreateddate': 'Created At',
  'text.custommessage.orderData': 'No data found for Orders',
  'form.user.placeholder.searchOrders': 'Search by Order by Name',
  'form.user.placeholder.searchCustomers': 'Search by Customer by Name or Email',

  'form.placeholder.first_name': 'First Name',
  'text.subTotal': 'Sub Total',
  'text.createddate': 'Created Date',
  'form.label.first_name': 'First Name',
  'form.placeholder.last_name': 'Last Name',
  'form.label.last_name': 'Last Name',
  'form.label.newpassword': 'New Password',
  'form.placeholder.newpassword': 'New Password',
  'form.label.confirmpassword': 'Confirm New Password',
  'form.placeholder.confirmpassword': 'Confirm New Password',
  'form.label.oldpassword': 'Current Password',
  'form.placeholder.oldpassword': 'Enter your current Password',
  'topBar.profileMenu.changePassword': 'Change Password',
  'topBar.profileMenu.logout': 'Logout',
  'text.copyright': 'Copyright © 2024 Sri Lakshmi Industries. All rights reserved.',
  'text.signIn.title': 'Sign in to your account',
  'text.dontHaveAccount': "Don't have an account?",
  'action.signUp': 'Sign Up',
  'action.next': 'Next',
  'action.gotosignin': 'Go to Sign in',
  'action.forgotPassword': 'Forgot password?',
  'action.signIn': 'Sign in',
  'form.label.password': 'Password',
  'form.error.password.max': 'Password must be up to 10 characters',
  'form.error.password.min': 'Password must be at least 8 characters',
  'form.error.password.required': 'Please input the password',
  'form.error.email.required': 'Please input the username or email address',
  'form.error.email.type': 'Invalid email address',
  'form.error.email.max': 'Email address must be up to 25 characters',
  'form.placeholder.email': 'Enter the email',
  'form.label.email': 'Email Id',
  'form.label.active': 'Active',
  'form.label.inactive': 'Inactive',
  'form.placeholder.company': 'Company Name',
  'form.label.company': 'Company Name',
  'form.error.empty.select': 'No matches found.',
  'form.placeholder.province': 'Enter the Province/State',
  'desc.logo': 'Logo Image',
  'title.helmet.orderinfo': 'Order Summary',
  'title.helmet.devicelogs': 'Device Info',
  'text.stacktrace': 'Stacktrace',
  'text.message': 'Message',
  'text.changepayment.description': ' ',
  'text.notes.button_message': 'Add Notes ',
  'form.storedetail.label.status': 'Account Status',
  'form.user.placeholder.search': 'Search by name or email or role',
  'form.label.agent_id': 'Agent ID',
  'text.resetpassword': 'Recover Password',
  'text.resetbutton': 'Reset',
  'title.recoverPassword': 'Recover Password',
  'action.recoverPassword': 'Recover my password',
  'title.resetPassword': 'Reset Password',
  'form.label.password.new': 'New Password',
  'form.label.password.confirm': 'Confirm Password',
  'action.resetPassword': 'Reset my password',
  'form.error.password.match': "Passwords doesn't match",
  'text.alreadyHaveAnAccount': 'Already have an account?',
  'action.Signin': 'Sign in',
  'text.placed': 'Order Placed Date',
  'text.reselleragent': ' Reseller Agent',
  'form.label.pos_software': 'Restaurant Software',
  'form.placeholder.storeinfo.pos_software': 'Restaurant Software',
  'form.placeholder.pos_software': 'Enter the Restaurant Software',
  'form.error.phone.invalid': 'Invalid phone number',
  'form.placeholder.agent_id': 'Agent ID',
  'text.custommessage.openorder': 'No open orders to show',
  'text.custommessage.closedorder': 'No closed orders to show',
  'text.custommessage.windowsclient': 'No windows client to show',
  'text.custommessage.paymentterminal': 'No payment terminal to show',
  'text.custommessage.activestores': 'No active stores to show',
  'text.custommessage.inactivestores': 'No Inactive stores to show',
  'text.custommessage.demostores': 'No Demo account to show',
  'text.custommessage.dailyReportData': 'No Daily Reports to show',
  'text.viewDailyReport.totalTime': 'Total Time',
  'text.custommessage.servicesData': 'No Services for accounts to show',
  'text.custommessage.userinfo': 'No users to show',
  'action.viewReceipt': 'View Receipt',
  'form.label.select.restaurant_software': ' ',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
