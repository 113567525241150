export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-user',
      url: '/dashboard',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Products',
      key: 'manage-products',
      icon: 'fe fe-user',
      url: '/manage/products',
    },
    {
      title: 'Manage Banner',
      key: 'manage-banner',
      icon: 'fe fe-user',
      url: '/manage/banner',
    },
    {
      title: 'Manage Customers',
      key: 'manage-customers',
      icon: 'fe fe-user',
      url: '/manage/customers',
    },
    {
      title: 'Manage Orders',
      key: 'manage-order',
      icon: 'fe fe-user',
      url: '/manage/orders',
    },
  ]
}
