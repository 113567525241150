import apiClient from 'services/axios'

const BASE_URL = '/api/orders'
const BASE_URL2 = '/api/customers'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAllCustomer() {
  return apiClient
    .get(`${BASE_URL2}/get-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function loadStatistics() {
  return apiClient
    .get(`${BASE_URL}/get-statistics`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadOrderDetails(payload) {
  return apiClient
    .post(`${BASE_URL}/get-order-details`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function changestatus(payload) {
  return apiClient
    .post(`${BASE_URL}/order-status-change`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

