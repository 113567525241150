import React, { useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu } from 'antd'
import UserAction from 'redux/user/actions'
import styles from './style.module.scss'
import ChangePasswordComp from './Change-Password'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(0)

  const userActiveLogin = user?.name

  const userRole = user?.userRole

  const CompanyName = 'Sri Lakshmi Industries'

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const changePassword = () => {
    dispatch({
      type: UserAction.SHOW_PASSWORD_MODAL,
    })
  }

  const hideChangePassword = () => {
    dispatch({
      type: UserAction.HIDE_PASSWORD_MODAL,
    })
  }

  const addCount = () => {
    // setCount(count + 1)
    setCount(count)
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <i className="fe fe-user mr-2" />
        {userActiveLogin}
      </Menu.Item>
      {/* {renderImageContainer} */}
      <Menu.Item>
        <i className="fe fe-users mr-2" />
        {userRole === 'superadmin' && <span>Super User</span>}
        {userRole === 'admin' && <span>Admin</span>}
        {userRole === 'user' && <span>User</span>}
      </Menu.Item>
      {userRole === 'superadmin' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {CompanyName}
        </Menu.Item>
      )}
      {userRole === 'admin' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {CompanyName}
        </Menu.Item>
      )}
      {userRole === 'user' && (
        <Menu.Item>
          <i className="fe fe-shopping-bag mr-2" />
          {CompanyName}
        </Menu.Item>
      )}

      <Menu.Item>
        <a href="javascript:void(0)" onClick={changePassword}>
          <i className="fe fe-lock mr-2" />
          <FormattedMessage id="topBar.profileMenu.changePassword" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
        <div className={styles.dropdown}>
          <Badge count={count}>
            <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
          </Badge>
        </div>
      </Dropdown>
      <ChangePasswordComp
        visible={user?.displayChangePasswordModal}
        title={`Change Password for - ${user?.name}`}
        onCancel={hideChangePassword}
        loading={user?.changing}
      />
    </>
  )
}

export default connect(mapStateToProps)(injectIntl(ProfileMenu))
