import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  updatecategory,
  loadAll,
  createproduct,
  loadAllProducts,
  loadProductImages,
  deleteproductImages,
} from 'api/products'
import actions from './actions'

export function* LOAD_ALL_CATEGORY() {
  const { response } = yield call(loadAll)
  if (response) {
    const { categoryList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        catagoryInfoData: categoryList,
      },
    })
  }
}

export function* UPDATE_CATEGORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatecategory, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_UPDATE_CATEGORY,
    })
    yield call(LOAD_ALL_CATEGORY)
  }
}

export function* ADD_PRODUCT_NAME({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createproduct, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE_PRODUCTS,
    })
    yield call(LOAD_ALL_PRODUCTS)
  }
}

export function* LOAD_ALL_PRODUCTS() {
  const { response } = yield call(loadAllProducts)
  if (response) {
    const { productList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        productInfoData: productList,
      },
    })
  }
}

export function* LOAD_PRODUCT_IMAGES({ payload }) {
  const { response } = yield call(loadProductImages, payload)
  if (response) {
    const { imagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        imageInfodata: imagesList,
        displayAddProductImageDrawer: true,
      },
    })
  }
}

export function* SHOW_DELETE_IMAGE({ payload }) {
  const { response } = yield call(deleteproductImages, payload)
  if (response) {
    const { imagesList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        imageInfodata: imagesList,
        displayAddProductImageDrawer: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL_CATEGORY, LOAD_ALL_CATEGORY),
    takeEvery(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeEvery(actions.ADD_PRODUCT_NAME, ADD_PRODUCT_NAME),
    takeEvery(actions.LOAD_ALL_PRODUCTS, LOAD_ALL_PRODUCTS),
    takeEvery(actions.LOAD_PRODUCT_IMAGES, LOAD_PRODUCT_IMAGES),
    takeEvery(actions.SHOW_DELETE_IMAGE, SHOW_DELETE_IMAGE),
  ])
}