const actions = {
  SET_ERROR_STATE: 'products/SET_ERROR_STATE',
  SET_STATE: 'products/SET_STATE',
  LOAD_ALL_CATEGORY: 'products/LOAD_ALL_CATEGORY',

  SHOW_CREATE_CATEGORY: 'products/SHOW_CREATE_CATEGORY',
  HIDE_CREATE_CATEGORY: 'products/HIDE_CREATE_CATEGORY',
  SHOW_UPDATE_CATEGORY: 'products/SHOW_UPDATE_CATEGORY',
  HIDE_UPDATE_CATEGORY: 'products/HIDE_UPDATE_CATEGORY',

  SHOW_CREATE_PRODUCTS: 'products/SHOW_CREATE_PRODUCTS',
  HIDE_CREATE_PRODUCTS: 'products/HIDE_CREATE_PRODUCTS',

  CREATE_CATEGORY: 'products/CREATE_CATEGORY',
  UPDATE_CATEGORY: 'products/UPDATE_CATEGORY',
  ADD_PRODUCT_NAME: 'products/ADD_PRODUCT_NAME',
  LOAD_ALL_PRODUCTS: 'products/LOAD_ALL_PRODUCTS',
  LOAD_PRODUCT_IMAGES: 'products/LOAD_PRODUCT_IMAGES',
  HIDE_PRODUCT_IMAGES: 'products/HIDE_PRODUCT_IMAGES',
  SHOW_DELETE_IMAGE: 'products/SHOW_DELETE_IMAGE',
}

export default actions
