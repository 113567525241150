import actions from './actions'

const initialState = {
  catagoryInfoData: null,
  productInfoData: null,

  searchBanner: null,

  displayAddBannerDrawer: false,
  creating: false,

  displayUpdateBannerDrawer: false,
  updating: false,
}

export default function configReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_CREATE_BANNER:
      return { ...state, displayAddBannerDrawer: true }
    case actions.HIDE_CREATE_BANNER:
      return { ...state, displayAddBannerDrawer: false }

    case actions.SHOW_UPDATE_BANNER:
      return { ...state, selectedData: action.payload.banner, displayUpdateBannerDrawer: true }
    case actions.HIDE_UPDATE_BANNER:
      return { ...state, selectedData: null, displayUpdateBannerDrawer: false }

    default:
      return state
  }
}
