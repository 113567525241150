const actions = {
  SET_ERROR_STATE: 'orders/SET_ERROR_STATE',
  SET_STATE: 'orders/SET_STATE',
  LOAD_ALL: 'orders/LOAD_ALL',
  SHOW_ORDER_DETAILS: 'orders/SHOW_ORDER_DETAILS',
  HIDE_ORDER_DETAILS: 'orders/HIDE_ORDER_DETAILS',
  LOAD_ALL_CUSTOMERS: 'orders/LOAD_ALL_CUSTOMERS',
  LOAD_STATISTICS: 'orders/LOAD_STATISTICS',
  CHANGE_ORDER_STATUS: 'orders/CHANGE_ORDER_STATUS',
}

export default actions
