import apiClient from 'services/axios'

const BASE_URL = '/api/banner'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBanner(payload) {
  return apiClient
    .post(`${BASE_URL}/update-banner`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
