import actions from './actions'

const initialState = {
  catagoryInfoData: null,
  productInfoData: null,
  selectedData: null,
  imageInfodata: null,

  searchCategory: null,
  searchproductname: null,
  company_data: null,

  displayAddCategoryDrawer: false,
  creating: false,
  displayUpdateCategoryDrawer: false,
  updating: false,

  displayAddProductsDrawer: false,
  displayAddProductImageDrawer: false,
}

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE_CATEGORY:
      return { ...state, displayAddCategoryDrawer: true }
    case actions.HIDE_CREATE_CATEGORY:
      return { ...state, displayAddCategoryDrawer: false }

    case actions.SHOW_UPDATE_CATEGORY:
      return { ...state, selectedData: action.payload.category, displayUpdateCategoryDrawer: true }
    case actions.HIDE_UPDATE_CATEGORY:
      return { ...state, displayUpdateCategoryDrawer: false }

    case actions.SHOW_CREATE_PRODUCTS:
      return { ...state, displayAddProductsDrawer: true }
    case actions.HIDE_CREATE_PRODUCTS:
      return { ...state, displayAddProductsDrawer: false }

    case actions.HIDE_PRODUCT_IMAGES:
      return { ...state, displayAddProductImageDrawer: false }

    default:
      return state
  }
}
