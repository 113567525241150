import apiClient from 'services/axios'

const BASE_URL = '/api/category'
const BASE_URL2 = '/api/product'

export async function updatecategory(payload) {
  const IdValue = payload?.categoryId
  delete payload?.categoryId
  return apiClient
    .put(`${BASE_URL}/update-category/${IdValue}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadAllProducts() {
  return apiClient
    .get(`${BASE_URL2}/get-list`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createproduct(payload) {
  return apiClient
    .post(`${BASE_URL2}/add-product`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadProductImages(payload) {
  return apiClient
    .post(`${BASE_URL2}/get-product-images`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteproductImages(payload) {
  return apiClient
    .post(`${BASE_URL2}/delete-product-images`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
