import store from 'store'
import actions from './actions'

const STORED_USER = storedUser => {
  const user = {}
  Object.keys(storedUser).forEach(key => {
    const item = store.get(`user.${key}`)
    user[key] = typeof item !== 'undefined' ? item : storedUser[key]
  })
  return user
}

export const userStateData = {
  id: '',
  name: '',
  role: '',
  email: '',
  avatar: '',
  accessToken: null,
  LogginedUserId: null,
  userName: null,
  userRole: null,
  myProfileInfo: null,
  myBankInfo: null,
  lastLogin: null,
  userId: null,
  data: null,
  business: null,
  businessLocation: null,
  loading: false,
  changing: false,
  submitting: false,
  signUpStep: 0,
  recovering: false,
  resetting: false,
  changingEmail: false,
  canResendEmail: true,
  confirming: false,
  resendingEmail: false,
  displayChangePasswordModal: false,

  errors: {
    register: null,
    change: null,
    login: null,
    recover: null,
    reset: null,
    business: null,
  },
}

const initialState = {
  ...STORED_USER(userStateData),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.SHOW_PASSWORD_MODAL:
      return { ...state, displayChangePasswordModal: true }
    case actions.HIDE_PASSWORD_MODAL:
      return { ...state, displayChangePasswordModal: false }
    default:
      return state
  }
}
