const actions = {
  SET_ERROR_STATE: 'config/SET_ERROR_STATE',
  SET_STATE: 'config/SET_STATE',
  LOAD_ALL: 'config/LOAD_ALL',

  SHOW_CREATE_BANNER: 'config/SHOW_CREATE_BANNER',
  HIDE_CREATE_BANNER: 'config/HIDE_CREATE_BANNER',
  SHOW_UPDATE_BANNER: 'config/SHOW_UPDATE_BANNER',
  HIDE_UPDATE_BANNER: 'config/HIDE_UPDATE_BANNER',
  UPDATE_BANNER: 'config/UPDATE_BANNER',
}

export default actions
